import React, { useContext, useEffect, useLayoutEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import Button from '../../../components/bootstrap/Button';
import { HeaderRight } from '../../../layout/Header/Header';
import OffCanvas, {
	OffCanvasBody,
	OffCanvasHeader,
	OffCanvasTitle,
} from '../../../components/bootstrap/OffCanvas';
import Alert from '../../../components/bootstrap/Alert';
import Dropdown, {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from '../../../components/bootstrap/Dropdown';
import Icon from '../../../components/icon/Icon';
import ThemeContext from '../../../contexts/themeContext';
import LANG, { getLangWithKey } from '../../../lang';
import showNotification from '../../../components/extras/showNotification';
import useDarkMode from '../../../hooks/useDarkMode';
import Popovers from '../../../components/bootstrap/Popovers';
import { userAuth } from '../../../contexts/userContext';

// eslint-disable-next-line react/prop-types
const CommonHeaderRight = ({ beforeChildren }) => {
	const { t } = useTranslation();
	const { setToken, token } = useContext(userAuth);

	const { darkModeStatus, setDarkModeStatus } = useDarkMode();

	const { fullScreenStatus, setFullScreenStatus } = useContext(ThemeContext);
	const styledBtn = {
		color: darkModeStatus ? 'dark' : 'light',
		hoverShadow: 'default',
		isLight: !darkModeStatus,
		size: 'lg',
	};

	useEffect(() => {
		const storedTheme = localStorage.getItem('theme');
		if (storedTheme !== null) {
			setDarkModeStatus(storedTheme === 'dark');
		}
	}, [setDarkModeStatus]);

	useEffect(() => {
		localStorage.setItem('theme', darkModeStatus ? 'dark' : 'light');
	}, [darkModeStatus]);

	const [offcanvasStatus, setOffcanvasStatus] = useState(false);

	const { i18n } = useTranslation();

	const changeLanguage = (lng) => {
		i18n.changeLanguage(lng);
		showNotification(
			<span className='d-flex align-items-center'>
				<Icon icon={getLangWithKey(lng)?.icon} size='lg' className='me-1' />
				<span>{`Language changed to ${getLangWithKey(lng)?.text}`}</span>
			</span>,
			// 'You updated the language of the site. (Only "Aside" was prepared as an example.)',
		);
	};

	// const user =JSON.parse(localStorage.getItem('user-info'));
	// const navigate = useNavigate();
	// function logout(){
	// 	localStorage.clear();
	// 	navigate("/");
	// }

	/**
	 * Language attribute
	 */
	useLayoutEffect(() => {
		document.documentElement.setAttribute('lang', i18n.language);
	});

	const navigate = useNavigate();
	const web_link = process.env.REACT_APP_WEB_LINK;
	const data_post = { action: 'cuslogout' };

	const logouthandle = async () => {
		//
		const result = await fetch(web_link, {
			method: 'post',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				'sdk-authorization': `${token.session_key}`,
			},
			body: JSON.stringify(data_post),
		});
		const req = await result.json();
		setToken({});
		localStorage.removeItem('user-auth');
		navigate('/');
	};

	return (
		<HeaderRight>
			<div className='row g-3'>
				{beforeChildren}
				{/* Dark Mode */}
				<div className='col-auto'>
					<Popovers trigger='hover' desc='Dark / Light mode'>
						<Button
							// eslint-disable-next-line react/jsx-props-no-spreading
							{...styledBtn}
							icon={darkModeStatus ? 'DarkMode' : 'LightMode'}
							onClick={() => setDarkModeStatus((prev) => !prev)}
							aria-label='Toggle fullscreen'
							data-tour='dark-mode'
						/>
					</Popovers>
				</div>

				{/* Lang Selector */}
				<div className='col-auto'>
					<Dropdown>
						<DropdownToggle hasIcon={false}>
							<Button
								// eslint-disable-next-line react/jsx-props-no-spreading
								{...styledBtn}
								icon={getLangWithKey(i18n.language)?.icon}
								aria-label='Change language'
								data-tour='lang-selector'
							/>
						</DropdownToggle>
						<DropdownMenu isAlignmentEnd data-tour='lang-selector-menu'>
							{Object.keys(LANG).map((i) => (
								<DropdownItem key={LANG[i].lng}>
									<Button
										icon={LANG[i].icon}
										onClick={() => changeLanguage(LANG[i].lng)}>
										{LANG[i].text}
									</Button>
								</DropdownItem>
							))}
						</DropdownMenu>
					</Dropdown>
				</div>

				{/*	Notifications */}
				{/* <div className='col-auto'>
					<Button
						// eslint-disable-next-line react/jsx-props-no-spreading
						{...styledBtn}
						icon='Notifications'
						onClick={() => setOffcanvasStatus(true)}
						aria-label='Notifications'
					/>
				</div>					 */}

				{/* Quick Panel */}
				<div className='col-auto'>
					<Dropdown>
						<DropdownToggle hasIcon={false}>
							{/* eslint-disable-next-line react/jsx-props-no-spreading */}
							<Button {...styledBtn} icon='Person' aria-label='Quick menu' />
						</DropdownToggle>
						<DropdownMenu isAlignmentEnd size='lg' className='py-0 overflow-hidden'>
							<div className='row g-0'>
								<div
									className={classNames(
										'col-12',
										'p-4',
										'd-flex justify-content-center',
										'fw-medium fs-5',
										'text-info',
										'border-bottom border-info',
										'bg-lo25-info',
									)}>
									{/* <span style={{ color: 'white' }}>{token.name}</span> */}
									<div
										style={{
											display: 'flex',
											flexDirection: 'column',
											alignItems: 'center',
										}}>
										<span style={{ color: 'white', fontSize: '16px' }}>
											{token.name}
										</span>
										<span style={{ color: 'white', fontSize: '12px' }}>
											{token.email}
										</span>
									</div>
								</div>
								<div
									className={classNames(
										'col-12 p-4 transition-base cursor-pointer',
										// 'border-end border-bottom',
										{ 'border-dark': darkModeStatus },
									)}>
									<div
										className='d-flex flex-column align-items-center justify-content-center'
										onClick={logouthandle}>
										{/* <Icon icon='Logout' size='3x' color='info' onClick={() => logout()}/> */}
										<div
											style={{
												display: 'flex',
												alignItems: 'center',
												gap: '10px',
												color: '#e17f7f',
											}}>
											<Icon icon='Logout' size='3x' />
											<span style={{ fontWeight: '500', fontSize: '14px' }}>
												{t('Log Out')}
											</span>
										</div>
									</div>
								</div>
								{/*<div
									className={classNames(
										// 'col-6 p-4 transition-base cursor-pointer bg-light-hover',
										'col-6 p-4 transition-base cursor-pointer',
										'border-bottom',
										{ 'border-dark': darkModeStatus },
									)}>
									<div
										className='d-flex flex-column align-items-center justify-content-center'
										onClick={() => {
											navigate('/setting-pages/vender');
										}}>
										<Icon icon='Settings' size='3x' color='success' />
										<span>Settings</span>
									</div>
								</div>*/}
							</div>
						</DropdownMenu>
					</Dropdown>
				</div>
				{/*	Full Screen */}
				<div className='col-auto'>
					<Popovers trigger='hover' desc='Fullscreen'>
						<Button
							// eslint-disable-next-line react/jsx-props-no-spreading
							{...styledBtn}
							icon={fullScreenStatus ? 'FullscreenExit' : 'Fullscreen'}
							onClick={() => setFullScreenStatus(!fullScreenStatus)}
							aria-label='Toggle dark mode'
						/>
					</Popovers>
				</div>
			</div>

			<OffCanvas
				id='notificationCanvas'
				titleId='offcanvasExampleLabel'
				placement='end'
				isOpen={offcanvasStatus}
				setOpen={setOffcanvasStatus}>
				<OffCanvasHeader setOpen={setOffcanvasStatus}>
					<OffCanvasTitle id='offcanvasExampleLabel'>Notifications</OffCanvasTitle>
				</OffCanvasHeader>
				<OffCanvasBody>
					<Alert icon='ViewInAr' isLight color='info' className='flex-nowrap'>
						4 new components added.
					</Alert>
					<Alert icon='ThumbUp' isLight color='warning' className='flex-nowrap'>
						New products added to stock.
					</Alert>
					<Alert icon='Inventory2' isLight color='danger' className='flex-nowrap'>
						There are products that need to be packaged.
					</Alert>
					<Alert icon='BakeryDining' isLight color='success' className='flex-nowrap'>
						Your food order is waiting for you at the consultation.
					</Alert>
					<Alert icon='Escalator' isLight color='primary' className='flex-nowrap'>
						Escalator will turn off at 6:00 pm.
					</Alert>
				</OffCanvasBody>
			</OffCanvas>
		</HeaderRight>
	);
};
CommonHeaderRight.propTypes = {
	beforeChildren: PropTypes.node,
	afterChildren: PropTypes.node,
};
CommonHeaderRight.defaultProps = {
	beforeChildren: null,
	afterChildren: null,
};

export default CommonHeaderRight;
