import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import './styles/styles.scss';
import './pages/common/vhelper/inputStyle.css'; //input date icon color
import { ThemeContextProvider } from './contexts/themeContext';
import './i18n';
import App from './App/App';
import reportWebVitals from './reportWebVitals';
import UserContextProvider from './contexts/userContext';
import './pages/common/vhelper/scroll.css';
// import Forgot from './pages/common/Forgot/forgot';
// import Changepw from './pages/common/Changepw/changepw';

ReactDOM.render(
	<Router>
		<React.StrictMode>
			<ThemeContextProvider>
				<UserContextProvider>
					<App />
				</UserContextProvider>
			</ThemeContextProvider>
		</React.StrictMode>
	</Router>,
	document.getElementById('root'),
);

reportWebVitals();
