import React, { useState, useRef, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import Button from '../../../components/bootstrap/Button';
import Logo from '../../../components/Logo';
import useDarkMode from '../../../hooks/useDarkMode';
import '../../presentation/auth/Login.css';
import Icon from '../../../components/icon/Icon';
// include styles
import 'rodal/lib/rodal.css';
import { Ralert } from '../vhelper/Normarodallalert';
import apiServicewt from '../../../api/apiServicewt';
import { useTranslation } from 'react-i18next';
import LANG, { getLangWithKey } from '../../../lang';
import { HeaderRight } from '../../../layout/Header/Header';
import Popovers from '../../../components/bootstrap/Popovers';
import Dropdown, {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from '../../../components/bootstrap/Dropdown';

// eslint-disable-next-line react/prop-types
const LoginHeader = ({ isNewUser }) => {
	const { t } = useTranslation();

	if (isNewUser) {
		return (
			<>
				<div className='text-center h1 fw-bold mt-5'>{t('Create Account,')}</div>
				<div className='text-center h4 text-muted mb-5'>{t('Sign up to get started!')}</div>
			</>
		);
	}
	return (
		<>
			<div className='text-center h2 fw-bold mt-5'>{t('Change Your Password')}</div>
			<br />
			<br />
			<div>{t('Enter Password below and we’ll reset your password')}</div>
			<br />
		</>
	);
};

const Login = ({ isSignUp }) => {
	const { t } = useTranslation();
	const { darkModeStatus, setDarkModeStatus } = useDarkMode();
	const { i18n } = useTranslation();
	const [isNewUser] = useState(isSignUp);

	const navigate = useNavigate();

	const lpasswordRef = useRef();
	const cpasswordRef = useRef();

	const [loadalert, setLoadalert] = useState(false);
	const [msgloadalert, setMsgLoadalert] = useState('');
	const [msgtypeloadalert, setMsgtypeLoadalert] = useState('');

	const [isemptypw, setIsemptypw] = useState({ error: false, msg: t('*Required') });
	const [isemptycpw, setIsemptycpw] = useState({ error: false, msg: t('*Required') });

	const [isLoading, setIsLoading] = useState(false);

	const styledBtn = {
		color: darkModeStatus ? 'dark' : 'light',
		hoverShadow: 'default',
		isLight: !darkModeStatus,
		size: 'lg',
	};

	useEffect(() => {
		const currentLang = getLangWithKey(i18n.language);

		if (!currentLang) {
			i18n.changeLanguage(LANG.EN.lng);
		}
	}, []);

	const changeLanguage = (lng) => {
		i18n.changeLanguage(lng);
		showNotification(
			<span className='d-flex align-items-center'>
				<Icon icon={getLangWithKey(lng)?.icon} size='lg' className='me-1' />
				<span>{`Language changed to ${getLangWithKey(lng)?.text}`}</span>
			</span>,
		);
	};

	useEffect(() => {
		const storedTheme = localStorage.getItem('theme');
		if (storedTheme !== null) {
			setDarkModeStatus(storedTheme === 'dark');
		}
	}, [setDarkModeStatus]);

	useEffect(() => {
		localStorage.setItem('theme', darkModeStatus ? 'dark' : 'light');
		document.documentElement.setAttribute('theme', darkModeStatus ? 'dark' : 'light');
	}, [darkModeStatus]);

	const closemodel = () => {
		setLoadalert(false);
	};
	const startmodel = () => {
		setLoadalert(true);
	};

	// cpw show
	const [cshowPassword, setCshowPassword] = useState(false);

	const ctogglePasswordVisibility = () => {
		setCshowPassword(!cshowPassword);
	};

	// pw show
	const [lshowPassword, setLshowPassword] = useState(false);

	const ltogglePasswordVisibility = () => {
		setLshowPassword(!lshowPassword);
	};

	const location = useLocation();

	// PW Change

	const pwchange = async () => {
		if (isLoading) return;
		let cansendapi = true;
		if (!lpasswordRef.current.value) {
			setIsemptypw({ error: true, msg: t('*Required') });
			cansendapi = false;
		} else {
			setIsemptypw({ error: false });
		}
		if (!cpasswordRef.current.value) {
			setIsemptycpw({ error: true, msg: t('*Required') });
			cansendapi = false;
		} else {
			setIsemptycpw({ error: false });
		}

		if (cansendapi) {
			setIsLoading(true);
			const item = {
				action: 'reset_password',
				cust_id: location.state.cust_id,
				hash_code: location.state.hash_code,
				password: lpasswordRef.current.value,
				confirm_password: cpasswordRef.current.value,
			};
			const flechdata = await apiServicewt(item, navigate);

			try {
				if (flechdata.type === 'Success' || flechdata.type === 'success') {
					// setUsernameInput(true);
					//
					// startmodel();
					// setMsgLoadalert(result.message);
					// setMsgtypeLoadalert(result.type);
					navigate('/');
					// fotpRef.current.value = '';
				} else {
					//
					startmodel();
					setMsgLoadalert(flechdata.message);
					setMsgtypeLoadalert(flechdata.type);
				}
			} catch (error) {
				console.error('Error fetching data:', error);
			} finally {
				setIsLoading(false);
			}
		}
	};

	return (
		<>
			<Ralert
				msgloadalert={msgloadalert}
				msgtypeloadalert={msgtypeloadalert}
				loadalert={loadalert}
				closemodel={closemodel}
			/>
			<div className='bg-img'>
				<PageWrapper title={t('Login')}>
					<div
						className='g-3'
						style={{
							position: 'absolute',
							top: 8,
							right: 8,
							display: 'flex',
							justifyContent: 'flex-end',
						}}>
						<div className='col-md-auto'>
							<div className='row g-3'>
								<div className='col-auto'>
									<Popovers trigger='hover' desc='Dark / Light mode'>
										<Button
											{...styledBtn}
											icon={darkModeStatus ? 'DarkMode' : 'LightMode'}
											onClick={() => setDarkModeStatus((prev) => !prev)}
											aria-label='Toggle fullscreen'
											data-tour='dark-mode'
										/>
									</Popovers>
								</div>
								<div className='col-auto'>
									<Dropdown>
										<DropdownToggle hasIcon={false}>
											<Button
												// eslint-disable-next-line react/jsx-props-no-spreading
												{...styledBtn}
												icon={getLangWithKey(i18n.language)?.icon}
												aria-label='Change language'
												data-tour='lang-selector'
											/>
										</DropdownToggle>
										<DropdownMenu isAlignmentEnd data-tour='lang-selector-menu'>
											{Object.keys(LANG).map((i) => (
												<DropdownItem key={LANG[i].lng}>
													<Button
														icon={LANG[i].icon}
														onClick={() => changeLanguage(LANG[i].lng)}>
														{LANG[i].text}
													</Button>
												</DropdownItem>
											))}
										</DropdownMenu>
									</Dropdown>
								</div>
							</div>
						</div>
					</div>
					<Page
						className='p-0'
						style={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							minHeight: '100vh',
						}}>
						{/* <div className='row h-100 align-items-center justify-content-center'>
							<div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'> */}
						{/* <br />
								<br />
								<br /> */}
						<Card
							className='shadow-3d-dark'
							data-tour='login-page'
							style={{
								maxWidth: '400px',
								maxHeight: '545px',
							}}>
							<CardBody
								style={{
									// width: '600px',
									// height: '545px',
									//display: 'flex',
									justifyContent: 'center',
								}}>
								<div className='text-center my-3'>
									<Link
										to='/'
										className={classNames(
											'text-decoration-none  fw-bold display-2',
											{
												'text-dark': !darkModeStatus,
												'text-light': darkModeStatus,
											},
										)}>
										<Logo width={200} />
									</Link>
								</div>

								<LoginHeader isNewUser={isNewUser} />

								<form className='row g-4'>
									<div className='col-12'>
										<FormGroup isFloating label={t('New Password')}>
											<Input
												type={lshowPassword ? 'text' : 'password'}
												ref={lpasswordRef}
											/>
											<button
												type='button'
												onClick={ltogglePasswordVisibility}
												style={{
													position: 'absolute',
													right: '10px', // Adjust the right position as needed
													top: '50%',
													transform: 'translateY(-50%)',
													border: 'none',
													background: 'none',
													cursor: 'pointer',
												}}>
												{lshowPassword ? (
													<Icon
														icon='Visibility'
														size='2x'
														style={{
															color: darkModeStatus
																? '#c9c3c3'
																: 'black',
														}}
													/>
												) : (
													<Icon
														icon='VisibilityOff'
														size='2x'
														style={{
															color: darkModeStatus
																? '#c9c3c3'
																: 'black',
														}}
													/>
												)}{' '}
											</button>
										</FormGroup>
										{isemptypw.error ? (
											<p
												style={{
													color: 'red',
													fontSize: '12px',
												}}>
												{' '}
												{isemptypw.msg ? isemptypw.msg : t('*Required')}
											</p>
										) : (
											<br />
										)}
									</div>
									<div className='col-12'>
										<FormGroup isFloating label={t('Repeat Password')}>
											<Input
												type={cshowPassword ? 'text' : 'password'}
												ref={cpasswordRef}
											/>
											<button
												type='button'
												onClick={ctogglePasswordVisibility}
												style={{
													position: 'absolute',
													right: '10px', // Adjust the right position as needed
													top: '50%',
													transform: 'translateY(-50%)',
													border: 'none',
													background: 'none',
													cursor: 'pointer',
												}}>
												{cshowPassword ? (
													<Icon
														icon='Visibility'
														size='2x'
														style={{
															color: darkModeStatus
																? '#c9c3c3'
																: 'black',
														}}
													/>
												) : (
													<Icon
														icon='VisibilityOff'
														size='2x'
														style={{
															color: darkModeStatus
																? '#c9c3c3'
																: 'black',
														}}
													/>
												)}{' '}
											</button>
										</FormGroup>
										{isemptycpw.error ? (
											<p
												style={{
													color: 'red',
													fontSize: '12px',
												}}>
												{' '}
												{isemptycpw.msg ? isemptycpw.msg : t('*Required')}
											</p>
										) : (
											<br />
										)}
									</div>
									<div className='col-12'>
										<Button
											color='success'
											className='w-100 py-3'
											onClick={() => pwchange()}
											disabled={isLoading}>
											{t('Submit')}
										</Button>
									</div>
								</form>
							</CardBody>
						</Card>
						{/* </div>
						</div> */}
					</Page>
				</PageWrapper>
			</div>
		</>
	);
};
Login.propTypes = {
	isSignUp: PropTypes.bool,
};
Login.defaultProps = {
	isSignUp: false,
};

export default Login;
